// NotFound.module.scss
.notFoundPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #000;
}

.title {
  font-size: 8rem;
  color: #ff004f; // Utilisation d'une couleur rouge pour bien marquer l'erreur
  margin: 0;
}

.message {
  font-size: 1.5rem;
  color: #fff;
  margin: 1rem 0;
}

.homeLink {
  padding: 0.8rem 2rem;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;

  &:hover {
    background-color: #0056b3;
  }
}
