.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  padding: 3px 20px 0;
  background-color: #000;
  position: relative;

  .logo {
    height: 50px;
    width: auto;
  }

  .burger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;

    .burgerBar {
      width: 100%;
      height: 3px;
      background-color: #fff;
      transition: all 0.3s ease;
    }

    @media (max-width: 768px) {
      display: flex;
    }
  }
}

.nav {
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #121212;
    z-index: 999;
    padding: 20px 0;

    &.navOpen {
      display: flex;
      justify-content: center; /* Centre les éléments horizontalement */
      align-items: center; /* Centre les éléments verticalement */
    }
  }
}

.navList {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;

  li {
    margin-right: 20px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    a,
    span {
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      padding: 10px;
      transition: color 0.3s ease;

      &:hover {
        color: #ff004f;
      }
    }

    /* Style pour l'élément actif */
    &.active {
      a,
      span {
        color: #ff004f; /* Couleur différente pour indiquer la page actuelle */
        font-weight: bold; /* Met en gras la page active */
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center; /* Centre les éléments du menu */
    width: 100%;

    li {
      width: 100%; /* Prendre toute la largeur disponible pour un bon centrage */
      text-align: center; /* Centre le texte dans chaque élément du menu */
      margin-bottom: 15px; /* Espace entre les éléments */
      margin-right: 0; /* Supprime la marge droite dans le menu burger */
    }
  }
}

.dropdown {
  cursor: pointer;

  .dropdownMenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    padding: 10px;
    list-style: none;
    margin: 0;
    min-width: 200px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1000;

    li {
      margin: 0;

      a {
        color: #fff;
        padding: 10px;
        display: block;
        white-space: nowrap;

        &:hover {
          background-color: #ff004f;
        }
      }
    }
  }

  &:hover .dropdownMenu {
    display: block;
  }

  &.dropdownMenuVisible {
    display: block;
  }

  @media (max-width: 768px) {
    .dropdownMenu {
      position: static;
      box-shadow: none;
      display: none;
      width: 100%;
    }

    &.dropdownMenuVisible .dropdownMenu {
      display: block;
    }
  }
}
