.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 250px;
  margin: 10px;

  &:hover {
    transform: translateY(-5px);
  }

  .media {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }

  .loadingGif {
    width: 100%; /* Le GIF prend toute la largeur de la vignette */
    height: 200px; /* Même hauteur que l'image */
    object-fit: cover; /* S'assure que le GIF est bien ajusté */
    border-radius: 8px 8px 0 0; /* Même bordure arrondie que pour l'image */
  }

  .title {
    margin-top: 10px;
    font-size: 1.2em;
    font-weight: 500;
    color: #fff;
  }
}
