.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #333;
  padding: 2rem;
  border-radius: 12px;
  max-width: 900px;
  width: 90%;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  max-height: 90vh;
  color: white;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 95%;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #2c2c2c;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff004f;
    border-radius: 10px;
    border: 3px solid #2c2c2c;
  }

  scrollbar-width: thin;
  scrollbar-color: #ff004f #2c2c2c;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #ff004f;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
}

.section {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

.sectionTitle {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.section:not(:last-child) {
  border-bottom: 1px solid #000;
  padding-bottom: 1.5rem;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
}

.video,
.slider {
  margin-top: 20px;
  max-width: 100%;
}

.softwares {
  margin-top: 20px;
}

.softwaresLogos {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.softwaresLogos img {
  max-width: 60px;
  height: auto;

  @media (max-width: 480px) {
    max-width: 40px;
  }
}

.pitch {
  font-style: italic;
}

.video {
  width: 100%; // Vidéo en pleine largeur
  height: 450px; // Ajuste cette valeur pour la hauteur souhaitée
  max-width: 800px; // Limite maximale de largeur si besoin
  margin: 0 auto; // Centrer la vidéo horizontalement
}
