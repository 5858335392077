.sliderContainer {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoSlide {
  width: 100%;
}

.video {
  width: 100%;
  height: 450px; /* Hauteur initiale de la vidéo */

  @media (max-width: 768px) {
    height: 300px; /* Hauteur responsive pour tablette */
  }

  @media (max-width: 480px) {
    height: 200px; /* Hauteur responsive pour mobile */
  }
}

/* Compteur de vidéos */
.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 10px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: bold;
}

/* Contrôles pour naviguer */
.controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.prevButton,
.nextButton {
  font-size: 1.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.prevButton:hover,
.nextButton:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .prevButton,
  .nextButton {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .prevButton,
  .nextButton {
    font-size: 1rem;
  }
}
