.confidentialityPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px; /* Add padding for smaller screens */
}

.content {
  max-width: 1200px;
  width: 100%;
  color: white;
  padding-bottom: 78px;
  padding-top: 50px;
}

.content h1 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.content h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.content p {
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .content h1 {
    font-size: 2.5rem;
  }

  .content h2 {
    font-size: 1.75rem;
  }

  .content p {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .content {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  .content h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .content h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .content p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .content h1 {
    font-size: 1.75rem;
  }

  .content h2 {
    font-size: 1.25rem;
  }

  .content p {
    font-size: 0.95rem;
    line-height: 1.5;
  }
}
