.footer {
  background-color: #1c1c1c;
  color: #d4d4d4;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .infoFooter {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1200px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .infoPratiques,
    .mesFormations,
    .mesReseaux {
      flex: 1;
      margin: 10px 20px;
      min-width: 220px;

      h3 {
        font-size: 1.2rem;
        color: #00aeef;
        border-left: 3px solid #00aeef;
        padding-left: 10px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 10px;
        }

        a {
          color: #d4d4d4;
          text-decoration: none;
          transition: color 0.3s;

          &:hover {
            color: #00aeef;
          }
        }
      }
    }

    .socialIcons {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;

      a {
        display: inline-block;
        transition:
          transform 0.3s ease,
          box-shadow 0.3s ease;

        &:hover {
          transform: scale(1.1);
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
        }

        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
      }
    }
  }

  .copyr {
    text-align: center;
    padding-top: 20px;
    width: 100%;
    font-size: 0.9rem;
    color: #888;
    border-top: 1px solid #333;
    margin-top: 20px;

    p {
      margin: 0;
    }
  }
}
