.contactContainer {
  width: 100%;
  max-width: 800px;
  margin: 5vh auto;
  margin-bottom: 70px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000; /* Dark background */
  padding: 20px; /* Add padding for breathing room */
  border-radius: 8px; /* Rounded corners */
  color: #eaeaea;
}

h1 {
  text-align: center; /* Centered title */
  margin-bottom: 1.5rem;
  font-size: 2.5rem; /* Larger font size */
  color: #fff; /* Bright yellow text similar to the image */
  text-transform: uppercase; /* Uppercase for a bold look */
  text-shadow: 2px 2px 5px #000; /* Text shadow for a standout effect */
}

.contactForm {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 1.2rem; /* Slightly larger labels */
  margin-bottom: 0.5rem;
  color: #ffffff; /* White text for better contrast */
}

input,
textarea {
  font-size: 1.1rem; /* Slightly larger input text */
  padding: 0.75rem;
  margin-bottom: 1.25rem;
  background-color: #1a1a1a; /* Darker background */
  border: 1px solid #555; /* Subtle border */
  border-radius: 5px;
  outline: none;
  color: #eaeaea;
  transition:
    border-color 0.2s ease,
    background-color 0.2s ease;

  &:focus {
    border-color: #00aeef;
    background-color: #333;
  }
}

textarea {
  min-height: 150px;
  resize: vertical;
}

.submitButton {
  background-color: #ff004f; /* Bright red button */
  color: #fff;
  font-size: 1.5rem; /* Larger font for the button */
  padding: 1rem; /* Larger padding for a bolder look */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase; /* Uppercase text for boldness */
  font-weight: bold;
  transition:
    background-color 0.2s ease,
    transform 0.2s ease;

  &:hover {
    background-color: #cc0000;
    transform: translateY(-2px); /* Small hover effect */
  }

  &:active {
    transform: translateY(0); /* Reset hover effect on click */
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .contactContainer {
    margin: 2vh auto;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  input,
  textarea {
    font-size: 1rem;
    padding: 0.75rem;
  }

  .submitButton {
    font-size: 1.25rem;
    padding: 0.75rem;
  }
}

@media (max-width: 480px) {
  .contactContainer {
    padding: 0;
  }

  h1 {
    font-size: 1.75rem;
  }

  input,
  textarea {
    font-size: 0.9rem;
    padding: 0.65rem;
  }

  .submitButton {
    font-size: 1.1rem;
    padding: 0.65rem;
  }
}
