$background-color: black;

@mixin fullViewportStyles {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: $background-color;
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
#app {
  @include fullViewportStyles;
  display: flex;
  flex-direction: column;
}

#app {
  min-height: 100vh; /* Assure que l'application occupe toute la hauteur de la page */
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1; /* Prend tout l'espace disponible avant le footer */
  display: flex;
  flex-direction: column;
}
