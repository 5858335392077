.homePage {
  width: 100%;
  background-color: #000; /* Fond noir pour toute la page */
}

.videoContainer {
  width: 100%;
  position: relative;
  overflow: hidden; /* Empêche la vidéo de déborder du conteneur */

  /* La hauteur de la vidéo est calculée pour remplir l'écran en tenant compte de la taille du header */
  height: calc(100vh - 80px);

  @media (max-width: 768px) {
    height: calc(100vh - 60px); /* Hauteur ajustée pour les petits écrans */
  }

  /* Ajout d'une gestion des petits écrans pour une meilleure adaptabilité */
  @media (max-width: 480px) {
    height: 40vh; /* Réduit la hauteur de la vidéo sur les très petits écrans */
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* La vidéo couvre entièrement le conteneur sans se déformer */

    /* Assurer une gestion correcte des petits écrans */
    @media (max-width: 480px) {
      object-fit: contain; /* Pour les très petits écrans, on ajuste pour que la vidéo ne soit pas coupée */
      height: 100%; /* S'assure que la vidéo ne dépasse pas du conteneur */
      width: 100%;
    }
  }
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  h2 {
    color: #fff;
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #121212;
    margin-bottom: 50px;
    padding: 20px;
    max-width: 1200px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.02);
    }

    .content {
      display: flex;
      flex-direction: column-reverse;
      gap: 20px;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      p {
        color: #d3d3d3;
        font-size: 1.3rem;
        line-height: 1.6;
        max-width: 600px;
        margin: auto;
        flex: 1;
      }

      .visu {
        flex: 1;

        .img {
          max-width: 100%;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.7);
        }
      }
    }

    .linkButton {
      display: inline-block;
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #b30032;
      color: #fff;
      border-radius: 8px;
      text-align: center;
      text-decoration: none;
      font-size: 1rem;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #ff004f;
      }
    }
  }
}
